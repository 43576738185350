import React, { useState, useContext, useMemo } from "react"
import { scroller } from 'react-scroll';

import AuthContext from '../../../context/AuthProvider';
import Button from "../../atoms/Button/Button"
import Checkbox from "../../atoms/Checkbox/Checkbox"
import { validateEmail } from '../../../helpers/general'
// import { gorgiasApi } from '../../../helpers/gorgias';

import * as styles from './ReturnsForm.module.css';
import { bcApi } from "../../../helpers/bigcommerce";
import { freshdeskApi } from "../../../helpers/freshdesk";
import { extractDate } from '../../../helpers/general';

const ReturnsForm = ({ orderDetails }) => {
    const auth = useContext(AuthContext);
    const isLoggedIn = auth && auth.state.isLoggedIn;
    const customerId = auth && auth.state.customerId;
    const usuMemberNumber = auth && (auth.state.usu?.MemberNumber || '');

    const customer = auth && 'object' in auth.state && auth.state.object;

    const initialFormState = {
        purchaseMade: '',
        orderId: '', 
        storeName: '',
        receiptNumber: '', 
        products: '', 
        paymentMethod: '',
        returnReason: '',
        faultDescription: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    }

    const [fields, setFields] = useState(initialFormState);
    const [requiredFields, setRequiredFields] = useState({
        purchaseMade: false,
        orderId: false, 
        storeName: false,
        receiptNumber: false, 
        products: false, 
        paymentMethod: false,
        returnReason: false,
        faultDescription: false,
        firstName: false,
        lastName: false,
        email: false,
        phone: false
    });
    const [checkboxValues, setCheckboxValues] = useState([]);
    const [displayValidation, setDisplayValidation] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [orders, setOrders] = useState();
    const [noOrderMsg, setNoOrderMsg] = useState();
    const [orderProducts, setOrderProducts] = useState();
    const [ticket, setTicket] = useState();

    const resetForm = () => {
        setFields(initialFormState);
    }

    useMemo(() => {
        if (customer) {
            setFields(f => ({...f, ...{
                firstName: customer.first_name,
                lastName: customer.last_name,
                email: customer.email,
            }}));
        }
        
        if (orderDetails) {
            setFields(f => ({...f, ...{
                purchaseMade: 'Website',
                orderId: orderDetails.id,
                paymentMethod: orderDetails.payment_method,
            }}));
        }
    }, [customer, orderDetails]);

    const validateForm = () => {

        const invalid = Object.keys(requiredFields).filter(required => {
            if (required === 'orderId' && 'purchaseMade' in fields && fields.purchaseMade === 'Store') return false;
            else if (required === 'storeName' && 'purchaseMade' in fields && fields.purchaseMade === 'Website') return false;
            else if (required === 'receiptNumber' && 'purchaseMade' in fields && fields.purchaseMade === 'Website') return false;
            else if (required === 'paymentMethod' && 'purchaseMade' in fields && fields.purchaseMade === 'Website') return false;
            else if (required === 'email') return !validateEmail(fields[required]);

            return (!(required in fields) || fields[required] === '')
        });

        if (invalid.length > 0) {
            // console.warn(invalid);
            setDisplayValidation(true);
            scroller.scrollTo('returns-form', {
                duration: 800,
                delay: 0,
                offset: -200,
                smooth: 'easeInOutQuart',
            });
            return false;
        }

        return true;
    }

    const setFieldValue = (fieldName, fieldValue) => {
        let outputName = fieldName;
        const outputValue = fieldValue;

        if (fieldName === 'productsArray') {
            outputName = 'products';
        }

        setDisplayValidation(false);
        const newField = {};
        newField[outputName] = outputValue;
        setFields(f => ({...f, ...newField}));
        const requiredField = {};
        requiredField[outputName] = (outputName === 'email') ? validateEmail(outputValue) : outputValue !== '';
        setRequiredFields(r => ({ ...r, ...requiredField }));
    }

    const fetchOrders = async customerId => {
        const endpoint = `orders?customer_id=${customerId}&sort=date_created:desc`;
        const tempOrders = await bcApi(endpoint, 'GET', null, 2).then(
            ({ response, status }) => {
            if (!response || status !== 200) {
                return [];
            }
            return response;
            }
        );

        if(tempOrders.length === 0) setNoOrderMsg('No orders found under this account. Please contact support.');
        setOrders(tempOrders);
    };

    const fetchProducts = async orderId => {
        const productsEndpoint = `orders/${orderId}/products`;
        const products = await bcApi(productsEndpoint, 'GET', null, 2).then(
            ({ response, status }) => {
            if (!response || status !== 200) {
                return [];
            }
            return response;
            }
        );
        setOrderProducts(products);
    };

    useMemo(() => {
        if('purchaseMade' in fields && fields.purchaseMade === 'Website' && isLoggedIn === true) {
            fetchOrders(customerId);
        }

        if('purchaseMade' in fields && fields.purchaseMade === 'Store') {
            setOrderProducts(undefined);
        }
    // eslint-disable-next-line
    }, [fields.purchaseMade, isLoggedIn]);

    useMemo(async () => {
        if('purchaseMade' in fields && fields.purchaseMade === 'Website' && isLoggedIn === true && fields.orderId) {
            fetchProducts(fields.orderId);
        }

        if(fields.orderId === '') {
            setOrderProducts(undefined);
            setFieldValue('products', '');
            setCheckboxValues([]);
        }

    // eslint-disable-next-line
    }, [fields.orderId])

    useMemo(() => {
        if(isLoggedIn === true) {
            setFields({
                ...fields,
                phone: auth.state.object.phone,
                firstName: auth.state.object.first_name,
                lastName: auth.state.object.last_name,
                email: auth.state.object.email,
            })
        }
    // eslint-disable-next-line
    }, [isLoggedIn])

    const buildMessage = (type) => {
        const nl = type === 'html' ? '<br />' : '\r\n';
        const message = `
            A return request has been made. Details below were submitted:${nl}${nl}
            ${Object.keys(fields).map(fieldKey => `${type === 'html' ? '<strong>' : ''}${fieldKey}:${type === 'html' ? '</strong>' : ''} ${fields[fieldKey]}`).join(nl)}
            ${nl}${nl}Please review and contact the customer to continue the process.
        `;

        return message;
    }

    const attemptSubmit = (e) => {
        e.preventDefault();
        const valid = validateForm();
        setIsSending(true);
        if (valid) {
            const messageHTML = buildMessage('html', fields);
            // const messageText = buildGorgiasMessage('text', fields);

            const { firstName, lastName, email, phone } = fields;
            const body = {
              custom_fields: {
                cf_full_name: `${firstName} ${lastName}`,
                cf_mobile: phone,
                cf_member_number: usuMemberNumber,
                cf_enquiry_related: "Online Store, Merchandise and Graduations"
              },
              email: email,
              type: 'Refund',
              subject: `Refund on Order ${fields.orderId}`,
              source: 2, //portal
              description: `${messageHTML}`,
              status: 2, //open -- do not remove
              priority: 1, //low -- do not remove,
              responder_id: 51023715896, // id of unisyd
            };

            freshdeskApi('/tickets', 'POST', body).then((response) => {
                setIsSubmitted(true);
                setTicket(response?.response.id);
            })

            // const body = {
            //     messages: [
            //         {
            //         // source: {
            //         //     to: [
            //         //     {
            //         //         name: process.env.GORGIAS_NAME,
            //         //         address: process.env.GORGIAS_EMAIL
            //         //     }
            //         //     ],
            //         //     from: {
            //         //     name: `${fields.firstName} ${fields.lastName}`,
            //         //     address: fields.email
            //         //     }
            //         // },
            //         body_html: messageHTML,
            //         body_text: messageText,
            //         subject: `ACTION: Return Request`,
            //         channel: 'email',
            //         from_agent: false,
            //         via: 'api'
            //         }
            //     ]
            // };
            
            // gorgiasApi(`tickets`, 'POST', body).then(() => {
            //     /* Gorgias ticket created - Add email to Klavyio list? */
            //     setIsSubmitted(true);
            // });
        } else {
            setIsSending(false);
        }
    }

    const listProducts = () => {
        return orderProducts.map((product, productIndex) => (
            <Checkbox 
                key={productIndex} 
                name="productsArray" 
                value={product.sku} 
                label={product.name} 
                checked={checkboxValues.indexOf(product.sku) > -1} 
                action={(e) => setCheckboxValues(c => {
                    const values = [...c];
                    const index = values.indexOf(`${product.sku} - ${product.name}`);
                    if (index > -1) {
                        values.splice(index, 1);
                    } else {
                        values.push(`${product.sku} - ${product.name}`);
                    }
                    setFieldValue('productsArray', values.join(', '));
                    return values;
                }
            )} />
        ))
    }

    if (isSubmitted) {
        return (
            <div className={styles.thankYou}>
                <h6>Thank you</h6>
                <p>We'll be in touch with further instruction.</p>
                <p>Ticket no:  <strong>{ticket}</strong></p>
            </div>
        )
    } else {
        return (
            <form className={styles.formContainer} id="returns-form" noValidate onSubmit={(e) => attemptSubmit(e)}>
                <h1>Returns Form</h1>
                <div className={styles.fieldSection}>
                    <h6>Order details</h6>
                    <div className={styles.mobileGrid}>
                        {!orderDetails && (
                            <>
                                <div className={`formField ${styles.mobileFormField}`}>
                                    <label htmlFor="purchaseMade" className={styles.formLabel}>Where did you make this purchase?</label>
                                    {/* {displayValidation && 'purchaseMade' in requiredFields && !requiredFields['purchaseMade'] && (
                                        <span className={styles.errorMsg}>Please select</span>
                                    )} */}
                                    <select name="purchaseMade" required={true} className={`${styles.formInput} ${(displayValidation && 'purchaseMade' in requiredFields && !requiredFields['purchaseMade']) ? styles.invalid : ''}`} defaultValue={fields['purchaseMade']} 
                                        onChange={(e) => {
                                            if(e.target.value === '') {
                                                resetForm();
                                                return;
                                            }
                                            setFieldValue('purchaseMade', e.target.value)
                                            }}>
                                        <option value="">Select an option...</option>
                                        <option value="Website">On the website</option>
                                        <option value="Store">In store</option>
                                    </select>
                                    {displayValidation && 'purchaseMade' in requiredFields && !requiredFields['purchaseMade'] && (
                                        <span className={styles.errorMsg}>Please select</span>
                                    )}
                                </div>

                                {'purchaseMade' in fields && fields.purchaseMade === 'Website' && isLoggedIn === false && (
                                    <div className={`formField ${styles.mobileFormField}`}>
                                        <label htmlFor="orderId" className={styles.formLabel}>Your order number</label>
                                        <input className={`${styles.formInput} ${(displayValidation && 'orderId' in requiredFields && !requiredFields['orderId']) ? styles.invalid : ''}`} required={true} type="text" name="orderId" value={fields['orderId']} onChange={(e) => setFieldValue('orderId', e.target.value)} />
                                        {displayValidation && 'orderId' in requiredFields && !requiredFields['orderId'] && (
                                            <span className={styles.errorMsg}>Please fill</span>
                                        )}
                                    </div>
                                )}

                                {'purchaseMade' in fields && fields.purchaseMade === 'Website' && isLoggedIn && (
                                    <div className={`formField ${styles.mobileFormField}`}>
                                        <label htmlFor="orderId" className={styles.formLabel}>Your order number</label>
                                        {/* <input className={`${styles.formInput} ${(displayValidation && 'orderId' in requiredFields && !requiredFields['orderId']) ? styles.invalid : ''}`} required={true} type="text" name="orderId" value={fields['orderId']} onChange={(e) => setFieldValue('orderId', e.target.value)} /> */}
                                        <select 
                                            name="orderId" 
                                            required={true} 
                                            // className={`${styles.formInput} ${(displayValidation && 'purchaseMade' in requiredFields && !requiredFields['purchaseMade']) ? styles.invalid : ''}`} defaultValue={fields['purchaseMade']} 
                                            className={`${styles.formInput}`} 
                                            // defaultValue={fields['purchaseMade']} 
                                            onChange={(e) => {setFieldValue('orderId', e.target.value)}}>
                                                <option value="">Select order id...</option>
                                                {orders?.map((o) => {
                                                    const orderDate = extractDate(o?.date_created);
                                                    return(
                                                        <option key ={o.id} value={o.id}>
                                                            {`${orderDate} - Order ${o.id}`}
                                                        </option>
                                                    )
                                                })}
                                        </select>
                                        {displayValidation && 'orderId' in requiredFields && !requiredFields['orderId'] && (
                                            <span className={styles.errorMsg}>Please fill</span>
                                        )}
                                        {noOrderMsg && <span className={styles.errorMsg}> {noOrderMsg}</span>}
                                    </div>
                                )}

                                {orderProducts && 'purchaseMade' in fields && fields.purchaseMade === 'Website' && (
                                    <div className={`formField ${styles.mobileFormField}`}>
                                        <label htmlFor="products" className={styles.formLabel}>What product(s) are you returning?</label>
                                        {displayValidation && 'products' in requiredFields && !requiredFields['products'] && (
                                            <span className={styles.errorMsg}>Please select</span>
                                        )}
                                        {listProducts()}
                                    </div>
                                )}

                                {'purchaseMade' in fields && fields.purchaseMade === 'Store' && (
                                    <>
                                        <div className={`formField ${styles.mobileFormField}`}>
                                            <label htmlFor="storeName" className={styles.formLabel}>Name & location of the store</label>
                                            <input className={`${styles.formInput} ${(displayValidation && 'storeName' in requiredFields && !requiredFields['storeName']) ? styles.invalid : ''}`} required={true} type="text" name="storeName" value={fields['storeName']} onChange={(e) => setFieldValue('storeName', e.target.value)} />
                                            {displayValidation && 'storeName' in requiredFields && !requiredFields['storeName'] && (
                                                <span className={styles.errorMsg}>Please fill</span>
                                            )}
                                        </div>
                                        <div className={`formField ${styles.mobileFormField}`}>
                                            <label htmlFor="receiptNumber" className={styles.formLabel}>Your receipt number</label>
                                            <input className={`${styles.formInput} ${(displayValidation && 'receiptNumber' in requiredFields && !requiredFields['receiptNumber']) ? styles.invalid : ''}`} required={true} type="text" name="receiptNumber" value={fields['receiptNumber']} onChange={(e) => setFieldValue('receiptNumber', e.target.value)} />
                                            {displayValidation && 'receiptNumber' in requiredFields && !requiredFields['receiptNumber'] && (
                                                <span className={styles.errorMsg}>Please fill</span>
                                            )}
                                        </div>
                                        <div className={`formField ${styles.mobileFormField}`}>
                                            <label htmlFor="paymentMethod" className={styles.formLabel}>How did you pay?</label>
                                            {displayValidation && 'paymentMethod' in requiredFields && !requiredFields['paymentMethod'] && (
                                                <span className={styles.errorMsg}>Please select</span>
                                            )}
                                            <select name="paymentMethod" required={true} className={`${styles.formInput} ${(displayValidation && 'paymentMethod' in requiredFields && !requiredFields['paymentMethod']) ? styles.invalid : ''}`} defaultValue={fields['paymentMethod']} onBlur={(e) => setFieldValue('paymentMethod', e.target.value)}>
                                                <option value="">Select an option...</option>
                                                <option value="Debit Card">Debit Card</option>
                                                <option value="Credit Card">Credit Card</option>
                                                <option value="Cash">Cash</option>
                                            </select>
                                        </div>
                                    </>
                                )}

                                <div className={`formField ${styles.mobileFormField}`}>
                                    <label htmlFor="products" className={styles.formLabel}>What product(s) are you returning?</label>
                                    <textarea className={`${styles.formInput} ${styles.formTextArea} ${(displayValidation && 'products' in requiredFields && !requiredFields['products']) ? styles.invalid : ''}`} required={true} name="products" value={fields['products']} onChange={(e) => setFieldValue('products', e.target.value)} />
                                    {displayValidation && 'products' in requiredFields && !requiredFields['products'] && (
                                        <span className={styles.errorMsg}>Please fill</span>
                                    )}
                                </div>
                            </>
                        )}

                        <div className={`formField ${styles.mobileFormField}`}>
                            <label htmlFor="returnReason" className={styles.formLabel}>What is the reason for returning the product(s)?</label>
                            <select name="returnReason" required={true} className={`${styles.formInput} ${(displayValidation && 'returnReason' in requiredFields && !requiredFields['returnReason']) ? styles.invalid : ''}`} defaultValue={fields['returnReason']} onBlur={(e) => setFieldValue('returnReason', e.target.value)}>
                                <option value="">Select an option...</option>
                                <option value="Change of mind">Change of mind (30 days)</option>
                                <option value="Wrong item received">Wrong item received</option>
                                <option value="Faulty item">Faulty item</option>
                                <option value="Damaged in transit">Damaged in transit</option>
                            </select>
                            {displayValidation && 'returnReason' in requiredFields && !requiredFields['returnReason'] && (
                                <span className={styles.errorMsg}>Please select</span>
                            )}
                        </div>

                        <div className={`formField ${styles.mobileFormField}`}>
                            <label htmlFor="faultDescription" className={styles.formLabel}>Please describe the fault</label>
                            <textarea className={`${styles.formInput} ${(displayValidation && 'faultDescription' in requiredFields && !requiredFields['faultDescription']) ? styles.invalid : ''}`} required={true} name="faultDescription" value={fields['faultDescription']} onChange={(e) => setFieldValue('faultDescription', e.target.value)} />
                            {displayValidation && 'faultDescription' in requiredFields && !requiredFields['faultDescription'] && (
                                <span className={styles.errorMsg}>Please fill</span>
                            )}
                        </div>
                    </div>
                </div>

                <div className={styles.fieldSection}>
                    <h6>Contact details</h6>
                    <div className={styles.mobileGrid}>
                        {!customer && (
                            <>
                                <div className={`formField ${styles.mobileFormField}`}>
                                    <label htmlFor="firstName" className={styles.formLabel}>First Name</label>
                                    <input className={`${styles.formInput} ${(displayValidation && 'firstName' in requiredFields && !requiredFields['firstName']) ? styles.invalid : ''}`} required={true} type="text" name="firstName" value={fields['firstName']} onChange={(e) => setFieldValue('firstName', e.target.value)} />
                                    {displayValidation && 'firstName' in requiredFields && !requiredFields['firstName'] && (
                                        <span className={styles.errorMsg}>Please fill</span>
                                    )}
                                </div>

                                <div className={`formField ${styles.mobileFormField}`}>
                                    <label htmlFor="lastName" className={styles.formLabel}>Last Name</label>
                                    <input className={`${styles.formInput} ${(displayValidation && 'lastName' in requiredFields && !requiredFields['lastName']) ? styles.invalid : ''}`} required={true} type="text" name="lastName" value={fields['lastName']} onChange={(e) => setFieldValue('lastName', e.target.value)} />
                                    {displayValidation && 'lastName' in requiredFields && !requiredFields['lastName'] && (
                                        <span className={styles.errorMsg}>Please fill</span>
                                    )}
                                </div>

                                <div className={`formField ${styles.mobileFormField}`}>
                                    <label htmlFor="email" className={styles.formLabel}>Email address</label>
                                    <input className={`${styles.formInput} ${(displayValidation && 'email' in requiredFields && !requiredFields['email']) ? styles.invalid : ''}`} required={true} type="email" name="email" value={fields['email']} onChange={(e) => setFieldValue('email', e.target.value)} />
                                    {displayValidation && 'email' in requiredFields && !requiredFields['email'] && (
                                        <span className={styles.errorMsg}>Please fill</span>
                                    )}
                                </div>
                            </>
                        )}

                        <div className={`formField ${styles.mobileFormField}`}>
                            <label htmlFor="phone" className={styles.formLabel}>Preferred contact number</label>
                            <input 
                                className={`${styles.formInput} ${(displayValidation && 'phone' in requiredFields && !requiredFields['phone']) ? styles.invalid : ''}`} 
                                required={true} 
                                type="text" 
                                name="phone" 
                                value={fields['phone']} 
                                onChange={(e) => {
                                    if(isNaN(e.target.value) === false) {
                                        setFieldValue('phone', e.target.value)
                                    }
                                }} />
                            {displayValidation && 'phone' in requiredFields && !requiredFields['phone'] && (
                                <span className={styles.errorMsg}>Please fill</span>
                            )}
                        </div>

                        <div className="formField">
                            <Button fullWidth className={styles.formSubmit} level="primary" type="buttonSubmit" disabled={isSending}>{isSending ? 'Sending...' : 'Submit'}</Button>
                        </div>
                    </div>
                </div>
                
                {/* <div className={styles.fieldSection}>
                    <div className="formField">
                        <Button className={styles.formSubmit} level="primary" type="buttonSubmit" disabled={isSending}>{isSending ? 'Sending...' : 'Submit'}</Button>
                    </div>
                </div> */}
            </form>
        );
    }
};

export default ReturnsForm;
